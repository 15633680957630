import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
              Sheer Analytics and Insights is committed to protecting your privacy. This privacy policy explains how
we collect, use, and protect your personal information.             
              </p>
              <br />
              <h1>Information we collect:</h1>
              <p>We may collect personal information, such as your name, email address, phone number, and
company name, when you subscribe to our services, fill out a contact form, or sign up for our
newsletter.</p>
              <h1>How we use your information:</h1>
            <p>We may use your personal information to:
<div>• Provide you with our services;</div>
<div>• Communicate with you about our services and promotions;</div>
<div>• Respond to your inquiries;</div>
<div>• Improve our services.</div>
<div>• We will not sell, rent, or share your personal information with third parties without your
consent, except as required by law.</div></p>
<h1>Cookies:</h1>
<p>We use cookies on our website to enhance your user experience. Cookies are small files that are
placed on your computer or mobile device when you visit our website. We may use both session
cookies (which expire when you close your browser) and persistent cookies (which remain on your
computer until they expire, or you delete them) to provide you with a more personalized experience
on our website.</p>
<h1>Security:</h1>
<p>We take the security of your personal information seriously and have implemented appropriate
technical and organizational measures to protect your information against unauthorized access,
disclosure, alteration, or destruction.</p>
<h1>Links to Third-Party Websites:</h1>
<p>Our website may contain links to third-party websites. We are not responsible for the privacy
practices or content of these websites. We encourage you to read the privacy policies of these
websites before providing any personal information.</p>
<h1>Changes to Privacy Policy:</h1>
<p>We reserve the right to modify or update this privacy policy at any time without prior notice. Your
continued use of our website and services after any such changes constitutes your acceptance of the
new privacy policy.</p>
          </div>
          <p>Thank you for choosing Sheer Analytics and Insights!</p>
        </div>
    </section>
  )
}
