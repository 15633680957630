import React from "react"

import PrivacyPolicy from "../components/PrivacyPolicy/Home"
import SEO from "../components/Base/SEO"
import InnerPageBanner from "../components/Shared/InnerPageBanner"
import Layout from "../components/Base/Layout"

export default function () {
  return (
    <Layout>
      <SEO title={"sheer analytics privacy policy"} />
      <InnerPageBanner headingText={"Privacy Policy"} linkText={"Privacy Policy"} />
      <PrivacyPolicy />
    </Layout>
  )
}